// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&family=Source+Code+Pro:wght@600&display=swap');

// Font Variables
$montserrat: 'Montserrat', sans-serif;
$source-code-pro: 'Source Code Pro', monospace;

// Colour variables
$light-orange: #db991e;
$light-orange-svg: invert(86%) sepia(23%) saturate(7268%) hue-rotate(349deg) brightness(91%)
  contrast(87%);
$white-svg: invert(100%) sepia(58%) saturate(2%) hue-rotate(31deg) brightness(111%) contrast(101%);

.app {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
}

.full-name {
  border: 8px dashed white;
  font-size: 9vw;
  padding: 20px 50px 20px 50px;

  .xav {
    color: $light-orange;
  }

  @media (min-width: 2000px) {
    font-size: 180px;
  }
}

.content {
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  @media (max-height: 480px) {
    flex-direction: row;
    // justify-content: center;
    margin: 0 30px 0 30px;
  }
}

.links {
  a {
    font-family: $montserrat;
    font-size: 24px;
    color: white;
    text-decoration-style: dotted;

    &:hover {
      color: $light-orange;
      text-decoration-style: solid;
    }
  }

  img {
    width: 20px;
    margin: 10px;
    filter: $white-svg;
    // icons come from https://icon-sets.iconify.design/feather/

    &:hover {
      filter: $light-orange-svg;
    }
  }
}

p {
  width: 75%;
  font-family: $montserrat;
}

h1 {
  font-family: $source-code-pro;
}
